import { observer } from "mobx-react-lite";
import { EmptyTable, RowAction, RowActions, SearchesTable } from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { userStore } from "../../stores";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import DeleteIcon from "../icons/delete";
import EditIcon from "../icons/edit";

function Searches() {
    const navigate = useNavigate();
    const intl = useIntl();
    const isFreeUser = false // userStore.type === "user-free" || userStore.type === "user-pending";

    const deleteSearch = async (search) => {
        // eslint-disable-next-line no-restricted-globals
        const yes = confirm(intl.formatMessage({ id: "confirm-delete-search" }));

        if (yes) {
            const res = await userStore.deleteSearch({
                "unique_identifier": search["unique_identifier"]
            });
            const [success, msg] = res;
            if (success) {
                await userStore.initToken();
            } else {
                toast.error(msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        }
    };

    return (
        <SearchesTable>
            <thead>
                <tr>
                    <th><FormattedMessage id="table-header-location" /></th>
                    <th><FormattedMessage id="table-header-rent" /></th>
                    <th><FormattedMessage id="table-header-actions" /></th>
                </tr>
            </thead>
            <tbody>
                {userStore.searches.length === 0 && (
                    <EmptyTable>
                        <FormattedMessage id="table-no-searches" />
                    </EmptyTable>
                )}
                {userStore.searches.map(search => (
                    <tr>
                        <td>{search.location}</td>
                        <td>
                            <FormattedMessage
                                id="table-price-cell"
                                values={{
                                    min: search.min,
                                    max: search.max
                                }}
                            />
                        </td>
                        <RowActions>
                            <RowAction onClick={() => navigate(`/edit/${search["unique_identifier"]}`)}>
                                <EditIcon />
                            </RowAction>
                            <RowAction onClick={async () => isFreeUser ? navigate("/subscriptions") : await deleteSearch(search)}>
                                <DeleteIcon />
                            </RowAction>
                        </RowActions>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <div>
                    <FormattedMessage
                        id="searches-left"
                        values={{
                            amount: <b>{userStore.maxSearches - userStore.searches.length}</b>
                        }}
                    />
                </div>
            </tfoot>
        </SearchesTable>
    );
}

export default observer(Searches);
