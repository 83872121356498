export const messages = {
    en: {
        // META TITLES
        "home-meta-title": "The Rent Friend - Effortlessly find your rental home",
        "subscriptions-meta-title": "Our prices",
        "faq-meta-title": "Frequently Asked Questions",
        "contact-meta-title": "Contact The Rent Friend",
        "register-meta-title": "Create Account",
        "login-meta-title": "Log In",
        "profile-meta-title": "Your personal dashboard",
        "forgot-password-meta-title": "Forgot password?",
        "create-meta-title": "Create search",
        "articles-meta-title": "Everything about the Dutch rental market",
        // END
        // START: META DESCRIPTIONS
        "home-meta-description": "Looking for a rental home? The Rent Friend monitors 2,000+ rental sites. Sign up and receive direct emails with available rental properties.",
        "subscriptions-meta-description": "Here you will find the rates of our subscriptions. Choose the membership that best suits your needs.",
        "faq-meta-description": "Find questions and answers to frequently asked questions about how you can find your new rental home using The Rent Friend.",
        "contact-meta-description": "Can't find your question on the FAQ page? Feel free to contact us via the contact form.",
        "register-meta-description": "Create an account and start your search.",
        "login-meta-description": "Log in with your account. Enter your email and password to continue.",
        "profile-meta-description": "Manage your data, searches, and view the latest rental properties here.",
        "forgot-password-meta-description": "Enter your email address. We will send you instructions to reset your password.",
        "create-meta-description": "Creating a search task? Easily set your preferences and instantly receive available rental properties that match your criteria.",
        "articles-meta-description": "Check out our blogs for the latest news, useful tips, and trends in the rental market.",
        // END
        "nav-prices": "Prices",
        "reasons-section-header": "Why does it work with us?",
        "reasons-one-header": "Save hours of searching",
        "reasons-one-description": "The Rent Friend finds almost every available rental property in the Netherlands. Over 2,000 housing websites are continuously monitored.",
        "reasons-two-header": "Respond first",
        "reasons-two-description": "As soon as a rental home becomes available that matches your search, you will immediately receive a notification via email.",
        "reasons-three-header": "More chance of success",
        "reasons-three-description": "With The Rent Friend, you ensure that you can respond first, view more quickly, and therefore have a greater chance of being able to rent a home.",
        "subscriptions-section-header": "Choose your subscription and respond immediately to matches",
        "subscriptions-section-header-mobile": "Choose your subscription",
        "subscription-card-duration": "month",
        "subscription-card-multi-duration": "months",
        "subscription-card-each-month": "each month",
        "subscription-card-amount-searches": "Up to {amount} at the same time",
        "subscription-card-amount-searches-mobile": "{amount}",
        "subscription-card-search-queries": "search queries",
        "subscription-card-months": "months",
        "subscription-card-activate": "Activate now",
        "subscription-displeased": "Not satisfied within the first 14 days? Get your money back",
        "money-back-contact": "* Go to contact page for more information",
        "auth-login": "Log In",
        "auth-register": "Create account",
        "auth-email-placeholder": "Email address",
        "auth-signup-password-placeholder": "The password must meet the following requirements:",
        "auth-password-placeholder": "Password",
        "password-length": "10 characters",
        "password-number": "1 or more numbers",
        "password-capitalize": "1 or more capital letters",
        "password-special-characters": "1 or more special characters",
        "auth-confirm-password-placeholder": "Confirm password",
        "password-same": "Both passwords must be the same",
        "auth-first-name-placeholder": "First name",
        "auth-last-name-placeholder": "Last name",
        "auth-nav-register": "Register",
        "auth-nav-login": "Login",
        "auth-nav-log-out": "Log out",
        "auth-nav-profile": "Profile",
        "create-new-search": "New Search",
        "table-searches-header": "Searches",
        "table-matches-header": "History",
        "table-header-location": "Location",
        "table-header-rent": "Rent",
        "table-header-actions": "Actions",
        "table-header-image": "Image",
        "table-header-found-on": "Found on",
        "table-price-cell": "€{min} - €{max} / month",
        "table-rooms-cell": " bedroom(s)",
        "table-no-searches": "You don't have any searches yet",
        "table-no-matches": "You have no matches yet",
        "searches-left": "You can create {amount} more search(es)",
        "fill-all-fields-search": "Not all required fields are filled in",
        "manage-search-address-label": "City, neighborhood, address, etc.",
        "manage-search-bedrooms-label": "Number of bedrooms",
        "manage-search-size-label": "Minimum size (sqm)",
        "manage-search-type-label": "Furnished / Upholstered",
        "confirm-delete-search": "Are you sure you want to delete this search?",
        "login-error-msg": "Failed to login with provided e-mail/password",
        "register-error-msg": "Failed to sign up with provided info",
        "payment-success-msg": "The payment was successful",
        "payment-fail-msg": "The payment has failed",
        "intro-title": "Find your rental home {break} {effortlessly}",
        "intro-effortlessly": "effortlessly",
        "intro-fast": "quickly",
        "intro-affordable": "affordably",
        "intro-subtitle": "We monitor more than 2,000 housing sites for you. Save time and increase your chance of finding a rental home!",
        "intro-btn-text": "Start your search for free",
        "intro-show-prices": "Show prices",
        "guarantee-one": "Makes renting easier",
        "guarantee-two": "New offers every day",
        "guarantee-three": "Active throughout the Netherlands",
        "prices-title": "Subscriptions and prices",
        "prices-description": "Choose a suitable subscription that meets your needs",
        "banner-text": "If you are looking for a rental property, the online supply is fragmented. There are dozens of real estate agents in every city, making constant monitoring of all websites impossible. {break} Even if you find something, you run the risk of others beating you to it.",
        "banner-text-mobile": "Due to the large number of housing websites, continuous monitoring is practically impossible. {break} We have the solution for you.",
        "account-inactive": "Account is not active ",
        "user-free-banner-text": " - You currently do not have an active subscription.",
        "faq-title": "Frequently asked questions",
        "faq-1-question": "Can The Rent Friend help me find a home?",
        "faq-1-answer": "In the rental market, finding a home seems almost impossible. House seekers respond massively to homes, but landlords usually only view the first few responses. If you're not at the top, you miss a viewing. The Rent Friend gives you a head start. Unlike traditional brokers who search manually, we use smart search bots. These bots constantly monitor more than 2,000 housing sites and send new homes directly to your phone. This allows you to discover homes faster than others, respond more quickly, and get invited to viewings more often.",
        "faq-2-question": "How long does it take on average to find a home through The Rent Friend?",
        "faq-2-answer": "Many home seekers have searched traditionally for months before trying The Rent Friend. And guess what? More than 63% of our users find a home within 8 weeks, some even in 3 to 4 weeks! Those are results we're proud of. The time to find a home varies by region and search query. For example, in popular cities like Amsterdam and Utrecht, with a rent up to €1.200, it usually takes 6 to 12 weeks. But that is still much faster than searching manually. And did you know that when you create a search query, we give you an estimate of the number of home matches per week?",
        "faq-3-question": "Does The Rent Friend also lease out apartments?",
        "faq-3-answer": "No, we do not rent out homes ourselves. In the Netherlands, there are already thousands of real estate agents and housing corporations active, so the last thing we need is another agent. In fact, keeping up with all the new listings is simply impossible. Our smart search bots monitor more than 2,000 housing sites for you, day and night. And we do more: we ensure that you can be the first to respond to homes. You receive new offers directly via email. If you respond quickly, you will be at the top and you will get an invitation for a viewing much more often. In short, we do not rent out homes ourselves, but we do help you find a home considerably faster!",
        "faq-4-question": "What exactly am I paying for?",
        "faq-4-answer": "Good question! With so many free and paid housing websites, which one should you choose? Our answer: the one that works best. Many housing websites show lists of rental homes. But as soon as you view an ad, thousands of others have probably done so too. You are often too late to respond and hear nothing back from landlords. We do it differently: we ensure that you can be the first to respond. With smart search bots that continuously follow more than 1,400 housing sites. As soon as a bot finds a home that is perfect for you, you will receive a message via email within a few seconds. Thanks to these housing alerts, you are the first to know and you can respond much faster. The result? Less wasted time and more invitations for viewings",
        "faq-5-question": "What is the income requirement?",
        "faq-5-answer": "The income requirement is determined by the landlord and therefore varies per property. Expect at least 3 times the basic rent, and in some cases even more. In other words, for a home with a basic rent of €1,000, the joint income of the tenants must be at least €3,000 gross per month. The income requirement is mentioned in almost every property advertisement",
        "faq-6-question": "Do I have to pay a commission?",
        "faq-6-answer": "No! The Rent Friend is not a real estate agent. We never charge anything when you find a home through us. Moreover, legally, an agent is not allowed to charge costs for mediating homes that are listed online. Tip: Are you responding to a home from a real estate agent? Pay the mediation costs and ask for them back later! {clickHere} for more information from the Dutch government.",
        "faq-7-question": "Can I also find social rental homes with The Rent Friend?",
        "faq-7-answer": "No, The Rent Friend only scans the housing offer within the private sector (from € 879,66). For social rental homes, there is a waiting list. Therefore, The Rent Friend cannot help you get a head start on the competition for social rental homes.",
        "faq-8-question": "What if I want to share a home?",
        "faq-8-answer": "You can use a filter for home sharers when placing a search query. However, not every landlord clearly indicates whether sharing the home is allowed. Only if this is explicitly stated, we filter this offer out. If it is unclear whether sharing is allowed, you will still receive the home just to be sure. You can then best determine the requirements for the relevant home by sending a response to the landlord",
        "contact-info-header": "Contact us",
        "contact-info-description": "Do you have a question? We'd love to hear from you. Send us a message, and we'll respond as soon as possible. Your question may have already been answered in the Frequently Asked Questions {link}. {break} Do you have a subscription and want to make use of the \"14 days not satisfied, money back\" guarantee? Just send us an email and we will arrange it for you immediately!",
        "contact-info-email": "Email: ",
        "contact-info-kvk": "Chambre of Commerce: ",
        "contact-info-vat": "VAT nr: ",
        "contact-form-name-placeholder": "Name",
        "contact-form-phone-nr-placeholder": "Phone number",
        "contact-form-message-placeholder": "Message",
        "contact-form-submit-btn": "Send",
        "contact-form-response": "Your message has been sent, we will contact you as soon as possible.",
        "contact-form-error": "Something went wrong while sending your message",
        "click-here": "Click here",
        "footer-quote": "Focus on responding and viewing {break} Skip the searching",
        "terms-and-conditions": "Terms and Conditions",
        "privacy-statement": "Privacy Statement",
        "contact": "Contact",
        "mobile-terms-and-conditions": "Conditions",
        "mobile-privacy-statement": "Privacy",
        "furnished": "Furnished",
        "not-furnished": "Not furnished",
        "no-preference": "No preference",
        "save": "Save",
        "forgot-password": "Forgot password?",
        "reset-password": "Reset password",
        "invalid-email": "The email address is invalid",
        "something-went-wrong": "Something went wrong",
        "email-does-not-exist": "E-mail does not exist",
        "requested-reset-password": "An e-mail has been sent to reset your password",
        "weak-password": "The filled in password is too weak",
        "token-does-not-exist": "Reset password token not found",
        "reset-password-success": "Password has been reset, you can log back in",
        "management": "Manage",
        "cancel-subscription": "Cancel subscription",
        "cancel-subscription-confirm": "Are you sure that you want to cancel your subscription?",
        "cancel-subscription-success": "Subscription has been cancelled",
        "no-subscription": "You don't have an active subscription",
        "funnel-select-placeholder": "Where do you want to live?",
        "funnel-select-label": "Search by city",
        "start-search": "Start free search",
        "select-place-error": "Select a city",
        "next": "Next",
        "back": "Back",
        "step": "Step",
        "search-location-placeholder": "Select a location",
        "articles": "Blog",
        "your-search": "Your search",
        "select-your-subscription": "Select your subscription"
    },
    nl: {
        // META TITLES
        "home-meta-title": "The Rent Friend - Vind moeiteloos jouw huurwoning",
        "subscriptions-meta-title": "Onze tarieven",
        "faq-meta-title": "Veelgestelde Vragen",
        "contact-meta-title": "Contact opnemen met The Rent Friend",
        "register-meta-title": "Account aanmaken",
        "login-meta-title": "Inloggen",
        "profile-meta-title": "Jouw persoonlijke dashboard",
        "forgot-password-meta-title": "Wachtwoord vergeten?",
        "create-meta-title": "Zoekopdracht maken",
        "articles-meta-title": "Alles over de Nederlandse huurmarkt",
        // END
        // START: META DESCRIPTIONS
        "home-meta-description": "Op zoek naar een huurwoning? The Rent Friend monitort 2.000+ verhuursites. Meld je aan en ontvang direct mail van beschikbare huurwoningen.",
        "subscriptions-meta-description": "Hier vind je de tarieven van onze abonnementen. Kies het lidmaatschap dat het beste bij jouw behoeften past.",
        "faq-meta-description": "Vind hier vragen en antwoorden op veelgestelde vragen over hoe jij jouw nieuwe huurwoning kunt vinden met behulp van The Rent Friend.",
        "contact-meta-description": "Staat jouw vraag niet op de FAQ pagina? Neem dan gerust contact met ons op via het contactformulier.",
        "register-meta-description": "Maak een account aan en start jouw gratis zoekopdracht.",
        "login-meta-description": "Log in met jouw account. Voer je mail en wachtwoord in om verder te gaan.",
        "profile-meta-description": "Beheer je gegevens, zoekopdrachten en bekijk hier de nieuwste huurwoningen.",
        "forgot-password-meta-description": "Voer jouw e-mailadres in. Wij sturen jou instructies om jouw wachtwoord opnieuw in te stellen",
        "create-meta-description": "Zoekopdracht aanmaken? Stel eenvoudig jouw voorkeuren in en ontvang direct beschikbare huurwoningen die aan jouw criteria voldoen.",
        "articles-meta-description": "Bekijk onze blogs voor het laatste nieuws, handige tips en trends binnen de verhuurmarkt.",
        // END
        "nav-prices": "Tarieven",
        "reasons-section-header": "Waarom lukt het bij ons wèl?",
        "reasons-one-header": "Bespaar uren aan zoeken",
        "reasons-one-description": "The Rent Friend vindt vrijwel iedere beschikbare huurwoning in Nederland. Er worden continu 2.000+ huizensites gemonitord.",
        "reasons-two-header": "Reageer als eerste",
        "reasons-two-description": "Zodra een huurwoning beschikbaar komt die matcht met jouw zoekopdracht, ontvang je binnen enkele seconden een e- mail. Zo kan je altijd als eerste reageren op woningen!",
        "reasons-three-header": "Meer kans van slagen",
        "reasons-three-description": "Met The Rent Friend zorg je dat jij als eerste kunt reageren, sneller kunt bezichtigen en de kans dus groter is dat je een woning kunt huren.",
        "subscriptions-section-header": "Kies je abonnement en reageer direct op woningmatches",
        "subscriptions-section-header-mobile": "Kies je abonnement",
        "subscription-card-duration": "maand",
        "subscription-card-multi-duration": "maanden",
        "subscription-card-each-month": "per maand",
        "subscription-card-amount-searches": "Tot {amount} tegelijk",
        "subscription-card-amount-searches-mobile": "{amount}",
        "subscription-card-search-queries": "zoekopdrachten",
        "subscription-card-months": "maanden",
        "subscription-card-activate": "Activeer nu",
        "subscription-displeased": "14 dagen niet goed, geld terug*",
        "money-back-contact": "* Zie contact pagina voor meer informatie",
        "auth-login": "Inloggen",
        "auth-register": "Account aanmaken",
        "auth-email-placeholder": "Email adres",
        "auth-signup-password-placeholder": "Het wachtwoord moet aan het volgende voldoen:",
        "password-length": "Minimaal 10 karakters",
        "password-number": "1 of meer getallen",
        "password-capitalize": "1 of meer hoofdletters",
        "password-special-characters": "1 of meer speciale karakters",
        "password-same": "Beide wachtwoorden moeten overeen komen",
        "auth-password-placeholder": "Wachtwoord",
        "auth-confirm-password-placeholder": "Bevestig wachtwoord",
        "auth-first-name-placeholder": "Voornaam",
        "auth-last-name-placeholder": "Achternaam",
        "auth-nav-register": "Aanmelden",
        "auth-nav-login": "Inloggen",
        "auth-nav-log-out": "Uitloggen",
        "auth-nav-profile": "Profiel",
        "create-new-search": "Nieuwe Zoekopdracht",
        "table-searches-header": "Zoekopdrachten",
        "table-matches-header": "Geschiedenis",
        "table-header-location": "Locatie",
        "table-header-rent": "Huur",
        "table-header-actions": "Acties",
        "table-header-image": "Afbeelding",
        "table-header-found-on": "Gevonden op",
        "table-price-cell": "€{min} - €{max} / maand",
        "table-rooms-cell": " slaapkamer(s)",
        "table-no-searches": "Je hebt nog geen zoekopdrachten",
        "table-no-matches": "Je hebt nog geen matches",
        "searches-left": "Je kan nog {amount} zoekopdracht(en) maken",
        "fill-all-fields-search": "Niet alle velden zijn ingevuld",
        "manage-search-address-label": "Stad, wijk, adres, etc.",
        "manage-search-bedrooms-label": "Aantal slaapkamers",
        "manage-search-size-label": "Minimum grootte (m²)",
        "manage-search-type-label": "Gemeubileerd / Bekleed",
        "confirm-delete-search": "Weet je zeker dat je deze zoekopdracht wilt verwijderen?",
        "login-error-msg": "Het is niet gelukt om in te loggen",
        "register-error-msg": "Het is niet gelukt om een account aan te maken",
        "payment-success-msg": "De betaling is voldaan",
        "payment-fail-msg": "De betaling is mislukt.",
        "intro-title": "Vind {effortlessly} {break} jouw huurwoning",
        "intro-effortlessly": "moeiteloos",
        "intro-fast": "snel",
        "intro-affordable": "betaalbaar",
        "intro-subtitle": "Wij houden voor jou 2.000+ verhuurwebsites in de gaten. Bespaar tijd en vergroot je kans op het vinden van een huurwoning!",
        "intro-btn-text": "Start gratis jouw zoekopdracht",
        "intro-show-prices": "Bekijk tarieven",
        "guarantee-one": "Maakt huren makkelijker",
        "guarantee-two": "Dagelijks veel nieuwe aanbod",
        "guarantee-three": "Actief in heel Nederland",
        "prices-title": "Abonnementen en prijzen",
        "prices-description": "Kies een passend abonnement dat aansluit op jouw behoeften. Eenvoudig opzegbaar!",
        "banner-text": "Als je een huurwoning zoekt, is het online aanbod versnipperd. In elke stad zijn tientallen makelaars, waardoor constant alle websites monitoren onmogelijk is. {break} Zelfs àls je iets vindt, loop je het risico dat anderen je voor zijn.",
        "banner-text-mobile": "Vanwege het grote aantal huizensites, is continu monitoren praktisch onmogelijk. {break} Wij hebben de oplossing voor jou.",
        "account-inactive": "Account is niet actief ",
        "user-free-banner-text": " - Je hebt momenteel geen actief abonnement.",
        "faq-title": "Veel gestelde vragen",
        "faq-1-question": "Kan The Rent Friend mij helpen een huis te vinden?",
        "faq-1-answer": "Op de huurmarkt lijkt het bijna onmogelijk om een huis te vinden. Huiszoekers reageren massaal op woningen, maar verhuurders bekijken meestal alleen de eerste paar reacties. Als je niet bovenaan staat, mis je een bezichtiging. The Rent Friend geeft je een voorsprong. In tegenstelling tot traditionele makelaars die handmatig zoeken, gebruiken wij slimme zoekbots. Deze bots monitoren constant meer dan 2.000 huizensites en sturen nieuwe woningen direct naar je telefoon. Hierdoor kun je sneller woningen ontdekken dan anderen, sneller reageren en vaker worden uitgenodigd voor bezichtigingen.",
        "faq-2-question": "Hoe lang duurt het gemiddeld om een huis te vinden via The Rent Friend?",
        "faq-2-answer": "Veel huiszoekers hebben maandenlang traditioneel gezocht voordat ze The Rent Friend probeerden. En raad eens? Meer dan 63% van onze gebruikers vindt binnen 8 weken een huis, sommigen zelfs in 3 tot 4 weken! Die resultaten maken ons trots. De tijd om een huis te vinden varieert per regio en zoekopdracht. Bijvoorbeeld, in populaire steden zoals Amsterdam en Utrecht, met een huurprijs tot €1.200, duurt het meestal 6 tot 12 weken. Maar dat is nog steeds veel sneller dan handmatig zoeken.",
        "faq-3-question": "Verhuurt The Rent Friend ook appartementen?",
        "faq-3-answer": "Nee, wij verhuren zelf geen woningen. In Nederland zijn er al duizenden makelaars en woningcorporaties actief, dus het laatste wat we nodig hebben is nog een makelaar. In feite is het bijhouden van alle nieuwe aanbiedingen simpelweg onmogelijk. Onze slimme zoekbots monitoren meer dan 2.000 huizensites voor je, dag en nacht. En we doen meer: we zorgen ervoor dat je als eerste kunt reageren op woningen. Je ontvangt nieuwe aanbiedingen direct via e-mail. Als je snel reageert, sta je bovenaan en krijg je veel vaker een uitnodiging voor een bezichtiging. Kortom, we verhuren zelf geen woningen, maar we helpen je wel aanzienlijk sneller een woning te vinden!",
        "faq-4-question": "Waar betaal ik precies voor?",
        "faq-4-answer": "Goede vraag! Met zoveel gratis en betaalde huizensites, welke moet je kiezen? Ons antwoord: degene die het beste werkt. Veel huizensites tonen lijsten met huurwoningen. Maar zodra je een advertentie bekijkt, hebben duizenden anderen dat waarschijnlijk ook gedaan. Je bent vaak te laat om te reageren en hoort niets terug van verhuurders. Wij doen het anders: we zorgen ervoor dat je als eerste kunt reageren. Met slimme zoekbots die continu meer dan 2000 huizensites volgen. Zodra een bot een woning vindt die perfect voor je is, ontvang je binnen enkele seconden een bericht via e-mail. Dankzij deze woningalerts ben je als eerste op de hoogte en kun je veel sneller reageren. Het resultaat? Minder verspilde tijd en meer uitnodigen voor bezichtigingen, waardoor je dus sneller een woning vindt!",
        "faq-5-question": "Wat is de inkomenseis?",
        "faq-5-answer": "De inkomenseis wordt bepaald door de verhuurder en varieert daarom per woning. Verwacht minimaal 3 keer de kale huur, en in sommige gevallen zelfs meer. Met andere woorden, voor een woning met een kale huur van €1.000, moet het gezamenlijke inkomen van de huurders minimaal €3.000 bruto per maand zijn. De inkomenseis wordt in bijna elke woningadvertentie vermeld.",
        "faq-6-question": "Moet ik een commissie betalen?",
        "faq-6-answer": "Nee! The Rent Friend is geen makelaar. Wij rekenen nooit kosten wanneer je via ons een woning vindt. Bovendien mag een makelaar wettelijk geen kosten in rekening brengen voor het bemiddelen van woningen die online staan. Tip: Reageer je op een woning van een makelaar? Betaal de bemiddelingskosten en vraag ze later terug! {clickHere} voor meer informatie van de Nederlandse overheid.",
        "faq-7-question": "Kan ik ook sociale huurwoningen vinden met The Rent Friend?",
        "faq-7-answer": "Nee, The Rent Friend scant alleen het woningaanbod binnen de particuliere sector (vanaf €879,66). Voor sociale huurwoningen is er een wachtlijst. Daarom kan The Rent Friend je geen voorsprong geven op de concurrentie voor sociale huurwoningen.",
        "faq-8-question": "Wat als ik een woning wil delen?",
        "faq-8-answer": "Je kunt een filter voor woningdelers gebruiken bij het plaatsen van een zoekopdracht. Echter, niet elke verhuurder geeft duidelijk aan of het delen van de woning is toegestaan. Alleen als dit expliciet wordt vermeld, filteren wij dit aanbod eruit. Als het onduidelijk is of delen is toegestaan, ontvang je de woning alsnog om er zeker van te zijn. Je kunt dan het beste de vereisten voor de betreffende woning bepalen door een reactie te sturen naar de verhuurder.",
        "contact-info-header": "Neem contact op",
        "contact-info-description": "Heb je een vraag? We horen graag van je. Stuur ons een bericht en we zullen zo snel mogelijk reageren. Je vraag kan mogelijk al beantwoord zijn in de Veelgestelde Vragen {link}. {break} Heb je een abonnement en wil je gebruik maken van de “14 dagen niet goed, geld terug” garantie? Stuur ons even een mailtje en we regelen het direct voor je!",
        "contact-info-email": "Email: ",
        "contact-info-kvk": "KvK: ",
        "contact-info-vat": "BTW nr: ",
        "contact-form-name-placeholder": "Naam",
        "contact-form-phone-nr-placeholder": "Telefoon",
        "contact-form-message-placeholder": "Bericht",
        "contact-form-submit-btn": "Verstuur",
        "contact-form-response": "Je bericht is verzonden, we zullen zo snel mogelijk contact opnemen.",
        "contact-form-error": "Iets is misgegaan met het verzenden van je bericht.",
        "click-here": "Klik hier",
        "footer-quote": "Richt je op het reageren en bezichtigen {break} Laat het zoeken achterwege",
        "terms-and-conditions": "Algemene Voorwaarden",
        "privacy-statement": "Privacy Verklaring",
        "contact": "Contact",
        "mobile-terms-and-conditions": "Voorwaarden",
        "mobile-privacy-statement": "Privacy",
        "furnished": "Gemeubileerd",
        "not-furnished": "Niet gemeubileerd",
        "no-preference": "Geen voorkeur",
        "save": "Opslaan",
        "forgot-password": "Wachtwoord vergeten?",
        "reset-password": "Wachtwoord resetten",
        "invalid-email": "De ingevulde e-mail is ongeldig",
        "something-went-wrong": "Er is iets misgegaan",
        "email-does-not-exist": "E-mail bestaat niet",
        "requested-reset-password": "Een e-mail is verstuurd om je wachtwoord te resetten",
        "weak-password": "Het ingevulde wachtwoord is te zwak",
        "token-does-not-exist": "Wachtwoord reset token niet gevonden",
        "reset-password-success": "Wachtwoord reset is gelukt, je kan weer inloggen",
        "management": "Beheer",
        "cancel-subscription": "Abonnement opzeggen",
        "cancel-subscription-confirm": "Weet je zeker dat je je abonnement wilt opzeggen?",
        "cancel-subscription-success": "Abonnement is opgezegd",
        "no-subscription": "Je hebt geen actieve abonnement.",
        "funnel-select-placeholder": "Waar wil je wonen?",
        "funnel-select-label": "Zoek op plaatsnaam",
        "start-search": "Start gratis zoekopdracht",
        "select-place-error": "Selecteer een plaatsnaam",
        "next": "Volgende",
        "back": "Terug",
        "step": "Stap",
        "search-location-placeholder": "Selecteer een locatie",
        "articles": "Blog",
        "your-search": "Jouw zoekopdracht",
        "select-your-subscription": "Selecteer jouw abonnement"
    }
};
