import { observer } from "mobx-react-lite";
import FunnelModal from "../funnelModal";
import { useEffect, useState } from "react";
import { StartFunnelBtn, StartFunnelSelectContainer, StartFunnelSelectLabel, StartSearchFunnelContainer } from "./styles";
import { SearchClient } from "../../agent/search";
import { SearchDropdownContainer, StyledSelect } from "../../pages/manageSearch/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { Bounce, toast, ToastContainer } from "react-toastify";


import { useNavigate } from "react-router-dom";
import { log } from "../../constants/firebase";
import { userStore } from "../../stores";
import { IntroButton, IntroButtonSubText, StartSearchContainer } from "../intro/styles";

function StartSearchFunnel() {
    const intl = useIntl();
    const [locations, setLocations] = useState([]);
    const [show, setShow] = useState(false);
    const [city, setCity] = useState("");
    const handleFunnelModalShow = (value) => setShow(value);
    const navigate = useNavigate();

    const onIntroButtonClick = () => {
        log("sign_up", { event_name: "from_home" });
        navigate(userStore.authenticated ? "/create" : "/register");
    };

    const startFunnel = () => {
        if (city !== "") {
            handleFunnelModalShow(true);
        } else {
            toast.error(intl.formatMessage({ id: "select-place-error" }), {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    useEffect(() => {
        SearchClient.locations().then(res => setLocations(res));
    }, []);

    return (
        <>
            <StartSearchFunnelContainer>
                <IntroButton onClick={onIntroButtonClick}>
                    <FormattedMessage id="intro-btn-text" />
                </IntroButton>
                {/* <StartFunnelSelectLabel>
                    <FormattedMessage id="funnel-select-placeholder" />
                </StartFunnelSelectLabel> */}

                {/* <StartFunnelSelectContainer>
                    <SearchDropdownContainer>
                        <StyledSelect
                            defaultValue={city}
                            onChange={(e) => setCity(e.target.value)}
                        >
                            <option value=""><FormattedMessage id="funnel-select-label" /></option>
                            {Object.keys(locations).map(location => (
                                <option value={location} key={location}>{location}</option>
                            ))}
                        </StyledSelect>
                    </SearchDropdownContainer>
                    <StartFunnelBtn onClick={startFunnel}>
                        <FormattedMessage id="start-search" />
                    </StartFunnelBtn>
                </StartFunnelSelectContainer> */}
            </StartSearchFunnelContainer>

            <FunnelModal show={show} handleShow={handleFunnelModalShow} selectedCity={city} />
            <ToastContainer />
        </>
    );
}

export default observer(StartSearchFunnel);
